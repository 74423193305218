<template>
  <section>
    <div v-for="(email, index) in form" :key="index + 'email'" class="form-group row">
        <label class="col-lg-3 col-form-label"><span v-if="index == 0">{{ computedLabel }}</span></label>
        <div class="col-lg-9">
          <div class="input-group">
            <input type="email" :id="'updateEMail_'+index" class="form-control" v-model="form[index]" required />
            <span class="input-group-append">
              <button type="button" class="btn btn-primary removeEMail" @click="removeEMail($event, index)">{{ $t('emails.form.remove-button') }}</button>
            </span>
          </div>
        </div>
    </div>
    <div v-if="!maxNumberOfEMails || form.length < maxNumberOfEMails" class="form-group row">
        <label class="col-lg-3 col-form-label">
          <span v-if="form.length == 0">{{ computedLabel }}</span>
        </label>
        <div class="col-lg-9">
          <div class="input-group">
            <input type="email" id="addEMail" class="form-control" v-model="addForm.email" :placeholder="computedPlaceholder" />
            <span class="input-group-append">
              <button type="button" class="btn btn-primary addEMail" :disabled="!isAddEMailEnabled" @click="addEMail">{{ $t('emails.form.add-button') }}</button>
            </span>
          </div>
        </div>
    </div>
  </section>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';

@Component({
  components: { }
})
export default class ListEMails extends Vue {

  @Prop({
    type: Array,
    required: false
  }) readonly emails!: any | undefined

  @Prop({
    type: Number,
    required: false
  }) readonly maxNumberOfEMails!: number | undefined

  @Prop({
    type: String,
    required: false
  }) readonly placeholder!: string

  @Prop({
    type: String,
    required: false
  }) readonly label!: string

  form:any[] = this.emails ? this.emails : [];
  
  computedPlaceholder = this.placeholder ? this.placeholder : ''
  computedLabel = this.label ? this.label : ''

  addForm = {
    email : ''
  }

  created() {
    if(this.computedLabel == "") { this.computedLabel = this.$t('emails.form.list-emails') as string;}
  }

  mounted() {
  }

  get isAddEMailEnabled() {
    return this.addForm.email != "";
  }

  addEMail(evt:Event) {
    evt.preventDefault();
    this.form.push(this.addForm.email);
    this.addForm.email = '';
  }

  removeEMail(evt:Event, index:number) {
    // We prevent submit of the page
    evt.preventDefault();

    Vue.delete(this.form, index);
  }

  @Watch('form', { deep: true })
  onFormChanged(val: any, oldVal: any) { 
    this.$emit('update:emails', val);
  }
  
}
</script>