<template>
  <div class="ibox shops">
    <div class="ibox-title">
      <h2>{{ $t('shop.promoCodes.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row m-b-md">
        <div class="col p-xs" v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</div>
      </div>
    
      <b-table 
          outlined striped
          :items="promoCodes"
          :fields="listFields"
          responsive
          ref="listItems">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
        </template>        
        <template v-slot:cell(title)="row">
          {{row.item.title}}
          <span v-if="row.item.subtitle && row.item.subtitle!=''">
            <br/>{{row.item.subtitle}}
          </span>
        </template>  
        <template v-slot:cell(promo)="row">
          <div v-html="getFormattedPromo(row.item)"></div>
        </template>
        <template v-slot:cell(isActivated)="row">
          <i :class="['fa','fa-check',row.item.isActivated ? 'active' : 'disabled']"></i>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isUpdateAllowed">
            <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('shop.promoCodes.options.update')}}</a>
            /
          </span>
          <span v-if="isRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('shop.promoCodes.options.delete')}}</a>
          </span>
        </template>
      </b-table>

      <button v-if="isCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('shop.promoCodes.create-button')}}</button>

      <b-modal size="xl" ref="createItemModal" :title="$t('shop.promoCodes.create.title')" hide-footer lazy>
        <PromoCodeForm v-on:promoCode-created="onItemCreated" />
      </b-modal>

      <b-modal size="xl" ref="updateItemModal" :title="$t('shop.promoCodes.update.title', {promoCodeID: itemToUpdate._id})" hide-footer lazy>
        <PromoCodeForm :promoCode="itemToUpdate" v-on:promoCode-updated="onItemUpdated" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('shop.promoCodes.delete-confirmation', {promoCodeID: itemToRemove._id})}}
      </b-modal>

    </div>
  </div>
</template>

<style scoped>

.fa-check.active {
    color:green;
  }
  .fa-check.disabled {
    color:red;
  }
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import PromoCodeForm from './PromoCodeForm.vue';

import { formatDate, formatDay } from '@igotweb-node-api-utils/formatter';
import { languagesTypes } from '@fwk-client/store/types';

import { useShopAdmin } from '../../../composables/useShopAdmin';
import { usePromoCodeAdmin } from '../../../composables/usePromoCodeAdmin';


@Component({
  components: { 
    PromoCodeForm
  }
})
export default class ListPromoCodes extends Vue {

  setup(props:any, context:any) {
    const { shops, selectedShop } = useShopAdmin(props, context);
    const { promoCodes, removePromoCode }  = usePromoCodeAdmin(props, context);
    return { shops, selectedShop, promoCodes, removePromoCode }
  }

  isCreateAllowed = true;
  isUpdateAllowed = true;
  isRemoveAllowed = true;
  isMultiSelectAllowed = false;
  showNumberOfRows = false;

  get numberOfRows():number {
    return this.promoCodes.value.length;
  }

  itemToRemove:any = {};
  itemToUpdate:any = {};

  listLoading:boolean = false;
  listItemsSelected = [];

  listFields = [
      {
        key: "select",
        label: "",
        class: (this.isMultiSelectAllowed ? "" : "hidden"),
      },
      {
        key: "_id",
        label: ""
      },
      {
        key: "code",
        label: ""
      },
      {
        key: "emails",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          if(value && value.length > 0) { return value.join(", "); }
          return this.$t('shop.promoCodes.emails.noEmail');;
        }
      },
      {
        key: "dates",
        label: "",
        formatter: this.datesFormatter()
      },
      {
        key: "promo",
        label: ""
      },
      {
        key: "isActivated",
        label: ""
      },
      {
        key: "options"
      }
    ];

  get currentLanguageCode() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }
  
  mounted() {
    this.listFields[1].label = this.$t('shop.promoCodes.headers.id') as string;
    this.listFields[2].label = this.$t('shop.promoCodes.headers.code') as string;
    this.listFields[3].label = this.$t('shop.promoCodes.headers.emails') as string;
    this.listFields[4].label = this.$t('shop.promoCodes.headers.dates') as string;
    this.listFields[5].label = this.$t('shop.promoCodes.headers.promo') as string;
    this.listFields[6].label = this.$t('shop.promoCodes.headers.isActivated') as string;
    this.listFields[7].label = this.$t('shop.promoCodes.headers.options') as string;
  }

  

  datesFormatter() {
    return (value:any, key:any, item:any) => {
      if(item.startDate && item.endDate) {
        var startDate = formatDay(item.startDate, this.currentLanguageCode, {isUTC:true});
        var endDate = formatDay(item.endDate, this.currentLanguageCode, {isUTC:true});
        return this.$t('shop.promoCodes.dates.range', [startDate, endDate]);
      }
      else if(item.startDate) {
        return this.$t('shop.promoCodes.dates.from', [formatDay(item.startDate, this.currentLanguageCode, {isUTC:true})]) as string;
      }
      else if(item.endDate) {
        return this.$t('shop.promoCodes.dates.until', [formatDay(item.endDate, this.currentLanguageCode, {isUTC:true})]) as string;
      }
      return this.$t('shop.promoCodes.dates.noDate');
    }
  }

  getFormattedPromo(promoCode:any) {
    if(promoCode.promos && promoCode.promos.length > 1) {
      var content = "<ul>"
      for(var promo of promoCode.promos) {
        content += "<li>" + this.formatPromo(promo) + "</li>"
      }
      content += "</ul>"
      return content;
    }
    else if(promoCode.promos && promoCode.promos.length == 1) {
      return this.formatPromo(promoCode.promos[0]);
    }
    return this.$t('shop.promoCodes.scopes.empty');
  }

  formatPromo(promo:any) {
    var scopeLabel = promo.scope.map((scope:string) => {
      return this.$t("shop.promoCodes.scopes.scope."+scope.toUpperCase())
    }).join(", ");
    var valueLabel = this.$t("shop.promoCodes.scopes.value."+promo.type.toUpperCase(), [promo.value]);
    return this.$t('shop.promoCodes.scopes.pattern', [scopeLabel,valueLabel]);
  }

  onItemRemoved() {
    // @ts-ignore
    this.$refs.listItems.refresh()
  }

  onItemCreated() {
    // @ts-ignore
    this.$refs.createItemModal.hide()
    // @ts-ignore
    this.$refs.listItems.refresh()
  }

  onItemUpdated(item:any) {
    // We update the promo code
    this.itemToUpdate = item;
    // @ts-ignore
    this.$refs.updateItemModal.hide()
    // @ts-ignore
    this.$refs.listItems.refresh()
  }
  
  confirmRemoveItem(item:any) {
    this.itemToRemove = item;
    // @ts-ignore
    this.$refs.removeItemModal.show()
  }

  showCreateItemModal() {
    // @ts-ignore
    this.$refs.createItemModal.show()
  }

  showUpdateModal(item:any) {
    this.itemToUpdate = item;
    // @ts-ignore
    this.$refs.updateItemModal.show()
  }

  removeItem() {
    this.listLoading = true;
    this.removePromoCode(this.itemToRemove._id).then((response:any) => {
      if(response.removed) {
        // We update the list of shops
        this.onItemRemoved();
      }
      // We reset the user to remove
      this.itemToRemove = {};

      this.listLoading = false;
    });
  }
}
</script>