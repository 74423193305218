<template>
  <div class="promoCodeScopes form-group row">
    <label for="updatePromoCodeScopes" class="col-lg-3 col-form-label">
      {{$t('shop.info.promoCode.scopes')}}
    </label>
    <div class="col-lg-9">
      <b-table 
          outlined striped
          :items="listScopes"
          :fields="listFields"
          show-empty
          :empty-text="$t('shop.promoCodes.scopes.empty')"
          ref="listScopes">
        <template v-slot:cell(options)="row">
          <a href="javascript:void(0)" @click="showUpdateModal(row.index)">{{$t('shop.promoCodes.scopes.list.options.update')}}</a> /
          <a href="javascript:void(0)" @click="confirmRemoveItem(row.index)">{{$t('shop.promoCodes.scopes.list.options.delete')}}</a>
        </template>
      </b-table>

      <textarea v-if="hasUserRole(apiRoles.superadmin)" id="updatePromoCodeScopes" :placeholder="$t('shop.info.promoCode.scopes_placeholder')" class="form-control" v-model="scopesText" />

      <button :disabled="availableScopes.length == 0" class="btn btn-primary" type="button" @click="showCreateItemModal()">{{$t('shop.promoCodes.create-button')}}</button>

      <b-modal size="xl" ref="createItemModal" :title="$t('shop.promoCodes.scopes.create.title')" :no-enforce-focus="true" no-close-on-backdrop hide-footer lazy>
        <PromoCodeScopeForm v-on:promoCodeScope-created="onItemCreated" :availableScopes="availableScopes" />
      </b-modal>

      <b-modal size="xl" ref="updateItemModal" :title="$t('shop.promoCodes.scopes.update.title')" hide-footer lazy>
        <PromoCodeScopeForm v-if="itemToUpdate != null" :promoCodeScope="form.promos[itemToUpdate]" v-on:promoCodeScope-updated="onItemUpdated" :availableScopes="availableScopes" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('shop.promoCodes.scopes.list.delete-confirmation')}}
      </b-modal>
    </div>
  </div>
</template>

<style>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { authenticationTypes } from '@fwk-client/store/types';
import { mapGetters } from '@fwk-node-modules/vuex';
import PromoCodeScopeForm from './PromoCodeScopeForm.vue';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';

@Component({
  components: { 
    PromoCodeScopeForm
  },
  computed: {
    ...mapGetters({
          hasUserRole : 'authentication/' + authenticationTypes.getters.HAS_USER_ROLE
    })
  }
})
export default class PromoCodeScopes extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly promoCode!: any

  emptyForm:any = {
    promos : []
  }

  form = { ...this.emptyForm }
  scopesText = "";

  itemToUpdate:any = null;
  itemToRemove:any = null;

  apiRoles = apiRoles;

  listFields:any = [
      {
        key: "scope",
        formatter: (value:any, key:any, item:any) => {
          return value.map((scope:string) => {
            return this.$t("shop.promoCodes.scopes.scope."+scope.toUpperCase())
          }).join(", ")
        }
      },
      {
        key: "value",
        formatter: (value:any, key:any, item:any) => {
          return this.$t("shop.promoCodes.scopes.value."+item.type.toUpperCase(), [item.value]);
        }
      },
      {
        key: "options",
      }
  ]

  listScopes() {
    return this.promoCode.promos;
  }

  get availableScopes() {
    console.log(this.form.promos);
    var usedScopes = (this.form && this.form.promos) ? this.form.promos.reduce((acc:string[], cur:any) => {
      return acc.concat(cur.scope)
    }, []) : [];
    return ["PRODUCTS_AMOUNT","DELIVERY_AMOUNT"].filter((availableScope:string) => {
      return usedScopes.indexOf(availableScope) < 0;
    });
  }


  created() {
    this.form = this.promoCode ? this.promoCode : { ...this.emptyForm }
    this.scopesText = JSON.stringify(this.form.promos, null, 2);
  }

  mounted() {
    this.listFields[0].label = this.$t('shop.promoCodes.scopes.list.headers.scope') as string;
    this.listFields[1].label = this.$t('shop.promoCodes.scopes.list.headers.value') as string;
    this.listFields[2].label = this.$t('shop.promoCodes.scopes.list.headers.options') as string;
  }

  showCreateItemModal() {
    // @ts-ignore
    this.$refs.createItemModal.show()
  }

  onItemCreated(createdScope:any) {
    // We update the promos
    this.form.promos.push(createdScope);
    this.scopesText = JSON.stringify(this.form.promos, null, 2);
    // @ts-ignore
    this.$refs.createItemModal.hide()
    // @ts-ignore
    this.$refs.listScopes.refresh()
  }

  showUpdateModal(index:any) {
    this.itemToUpdate = index;
    // @ts-ignore
    this.$refs.updateItemModal.show()
  }

  onItemUpdated(updatedScope:any) {
    // We update the promos
    this.form.promos[this.itemToUpdate] = updatedScope;
    this.scopesText = JSON.stringify(this.form.promos, null, 2);
    this.itemToUpdate = null;
    // @ts-ignore
    this.$refs.updateItemModal.hide()
    // @ts-ignore
    this.$refs.listScopes.refresh()
  }

  confirmRemoveItem(index:any) {
    this.itemToRemove = index;
    // @ts-ignore
    this.$refs.removeItemModal.show()
  }

  removeItem() {
    this.form.promos.splice(this.itemToRemove, 1);
    this.scopesText = JSON.stringify(this.form.promos, null, 2);
    this.itemToRemove = null;
  }

  @Watch('scopesText')
  onScopesTextChange(val:any, oldVal:any) {
    this.form.promos = JSON.parse(val);
  }

  @Watch('promoCode', { deep: true })
  onPromoCodeChange(val: any, oldVal: any) {
    if(val) {
      this.form = val;
    }
    else {
      this.form = { ...this.emptyForm }
    }
    this.scopesText = JSON.stringify(this.form.promos, null, 2)
    // @ts-ignore
    this.$refs.listScopes.refresh()
  }
  

  @Watch('form', { deep: true })
  onFormChange(val: any, oldVal: any) {
    this.emitValueChange();
  }

  emitValueChange() {
    this.$emit('update:promoCode', this.form);
  }
}
</script>