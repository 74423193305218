import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useShopAdmin } from "./useShopAdmin";

interface PromoCodeAdminInput {
}

export function usePromoCodeAdmin(props:PromoCodeAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedShop, callShopAdmin } = useShopAdmin(props, {emit})

  var isListLoading:Ref<boolean> = ref(false);
  var promoCodes:Ref<any[]> = ref([]);

  const updateList = async () => {

    isListLoading.value = true;
    try {
      var response = await callShopAdmin('/shop/'+selectedShop.value.shop._id+'/promo-codes');
      promoCodes.value = response.promoCodes ? response.promoCodes : [];
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateList();

  // We check when there is a new shop selected
  watch(
    () => selectedShop.value.shop._id,
    (val:any, oldVal:any) => {
      // We update the categories when the selected shop is updated
      updateList();
    },
    { deep: false }
  )

  const removePromoCode = async (promoCodeID:string) => {

    isListLoading.value = true;

    try {
      var response = await callShopAdmin('/promo-code/'+promoCodeID+'/remove');
      isListLoading.value = false;
      return response;
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return false;
  }

  return {
    promoCodes,
    removePromoCode
  }
  
}