<template>
  <form role="form" @submit="onFormSubmit" name="promo-code-form" class="promo-code-form">
    <TextField v-bind:value.sync="form.code" id="code" :label="$t('shop.info.promoCode.code')" :placeholder="$t('shop.info.promoCode.code_placeholder')" required labelAsColumn />
    <LocalizedText 
          ref="note"
          v-bind:value.sync="form.note" 
          id="updateNote" 
          :label="$t('shop.info.promoCode.note')" 
          :placeholder="$t('shop.info.promoCode.note_placeholder')" 
          :labelAsColumn="true"
          :required="false"
          :languageCodes="availableLanguageCodes"
          :rowClass="{'form-group':true, 'row':true, 'update-note':true}"
      />
    <ListEMails v-bind:emails.sync="form.emails" :label="$t('shop.info.promoCode.emails')" :placeholder="$t('shop.info.promoCode.emails_placeholder')" />
    <DateInput v-bind:value.sync="form.startDate" :label="$t('shop.info.promoCode.startDate')" labelAsColumn id="startDate" />
    <DateInput v-bind:value.sync="form.endDate" :label="$t('shop.info.promoCode.endDate')" labelAsColumn id="endDate" />
    <div class="form-group row">
      <label for="updateIsActivated" class="col-lg-3 col-form-label">{{$t('shop.info.promoCode.isActivated')}}</label>
      <div class="col-lg-9">
        <p-check id="updateIsActivated" class="p-switch p-fill" color="success" v-model="form.isActivated">&nbsp;</p-check>
      </div>
    </div>
    <div class="form-group row">
      <div class="col">
        <PromoCodeScopes v-bind:promoCode.sync="form"></PromoCodeScopes>
      </div>
    </div>
    <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ buttonLabel }}</button>
  </form>
</template>

<style>
  
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import DateInput from '@root/src/client/components/panels/input/DateInput.vue';
import ListEMails from '@root/src/client/components/panels/input/ListEMails.vue';
import LocalizedText from '@fwk-client/components/panels/input/LocalizedText.vue';
import PromoCodeScopes from './PromoCodeScopes.vue';
import * as Ladda from 'ladda';
import * as api from '@fwk-client/utils/api';

import { useShopAdmin } from '../../../composables/useShopAdmin';

@Component({
  components: {
    TextField,
    DateInput,
    ListEMails,
    LocalizedText,
    PromoCodeScopes
  }
})
export default class PromoCodeForm extends Vue {

  setup(props:any, context:any) {
    const { shops, selectedShop } = useShopAdmin(props, context);
    return { shops, selectedShop }
  }

  @Prop({
    type: Object,
    required: false
  }) readonly promoCode!: any | undefined

  form:any = {
    code: this.promoCode ? this.promoCode.code : '',
    note: this.promoCode ? this.promoCode.note : undefined,
    emails: (this.promoCode && this.promoCode.emails) ? this.promoCode.emails : [],
    startDate: (this.promoCode && this.promoCode.startDate) ? this.promoCode.startDateInput : '',
    endDate: (this.promoCode && this.promoCode.endDate) ? this.promoCode.endDateInput : '',
    promos: this.promoCode ? this.promoCode.promos : [],
    isActivated: this.promoCode ? this.promoCode.isActivated : false
  };

  availableLanguageCodes = ['fr','en'];

  get buttonLabel() {
    if(this.promoCode) { return this.$t('shop.update.button') }
    else { return "Create"; }
  }

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=promo-code-form] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  }  

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      ...this.form,
      "shopID" : this.selectedShop ? this.selectedShop.shop._id : undefined,
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();

    var path = '/api/admin/shop/promo-code/create';
    if(this.promoCode) {
      path = '/api/admin/shop/promo-code/'+this.promoCode._id+'/update';
    }
    
    api.postAPI(path, input, options).then((response:any) => {
      if(response.created) {  
        this.$emit('promoCode-created', response.promoCode);       
      }
      else if(response.updated) {  
        this.$emit('promoCode-updated', response.promoCode);
      }
      this.laddaSubmit!.stop();
    });
  }
  
}
</script>