<template>
  <section id="shop" ref="shopContent">
    <PageTitle :title="$t('shop.promoCodes.title')" :breadcrumbs="breadcrumbs"></PageTitle>
    
    <div class="wrapper wrapper-content animated fadeInRight">

      <Header></Header>

      <div class="row" v-if="selectedShop">
        <div class="col">
          <ListPromoCodes></ListPromoCodes>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';
import { enableIbox, disableIbox} from '@root/src/client/theme/inspinia-jquery'; 

import { useShopAdmin } from '../../composables/useShopAdmin';

import Header from '../panels/Header.vue';

import ListPromoCodes from '../panels/promoCodes/ListPromoCodes.vue';

@Component({
  components: {
    PageTitle,
    ListPromoCodes,
    Header
  }
})
export default class PromoCodes extends mixins(GenericPage) {

  setup(props:any, context:any) {
    const { shops, selectedShop } = useShopAdmin(props, context);
    return { shops, selectedShop }
  }

  get breadcrumbs() {
    return [{
      label: this.$t('home.dashboard')
    },
    {
      label: this.$t('shop.title')
    }]
  }

  mounted() {
    enableIbox(this.$refs.shopContent as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.shopContent as HTMLElement);
  }

}
</script>