<template>
  <form role="form" @submit="onFormSubmit" name="promo-code-scope-form" class="promo-code-scope-form">
    <div class="form-group row">
      <label for="scope" class="col-lg-3 col-form-label">{{ $t('shop.promoCodes.scopes.scope.label') }}</label>
      <div class="col-lg-9">
        <v-select ref="listScopes" multiple :options="listScopes" label="label" :getOptionLabel="(code) => $t('shop.promoCodes.scopes.scope.' + code)" id="scope" :placeholder="$t('shop.promoCodes.scopes.scope.placeholder')" @keypress.enter.native.prevent="" v-model="form.scope" />
      </div>
    </div>

    <div class="form-group row">
      <label for="type" class="col-lg-3 col-form-label">{{ $t('shop.promoCodes.scopes.type.label') }}</label>
      <div class="col-lg-9">
        <v-select ref="listTypes" :options="listTypes" :getOptionLabel="(code) => $t('shop.promoCodes.scopes.type.' + code)" id="type" :placeholder="$t('shop.promoCodes.scopes.type.placeholder')" @keypress.enter.native.prevent="" v-model="form.type"/>
      </div>
    </div>
    <TextField 
          ref="value"
          v-bind:value.sync="form.value" 
          id="value" 
          :label="$t('shop.promoCodes.scopes.value.label')" 
          :placeholder="$t('shop.promoCodes.scopes.value.placeholder')" 
          :labelAsColumn="true"
          :required="true"
          :rowClass="{'form-group':true, 'row':true, 'value':true}"
      />
    <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ buttonLabel }}</button>
  </form>
</template>

<style>
  
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import TextField from '@fwk-client/components/panels/input/TextField.vue';

@Component({
  components: {
    TextField
  }
})
export default class PromoCodeScopeForm extends Vue {

  @Prop({
    type: Object,
    required: false
  }) readonly promoCodeScope!: any | undefined

  @Prop({
    type: Array,
    required: false
  }) readonly availableScopes!: string[] | undefined

  form:any = {
    scope: this.promoCodeScope ? this.promoCodeScope.scope : [],
    type: this.promoCodeScope ? this.promoCodeScope.type : [],
    value: this.promoCodeScope ? this.promoCodeScope.value : '',
  };

  get listScopes() {
    if(this.availableScopes) {
      return this.availableScopes;
    }
    return ["PRODUCTS_AMOUNT","DELIVERY_AMOUNT"];
  }

  get listTypes() {
    return ["AMOUNT","PERCENTAGE"];
  }

  get buttonLabel() {
    if(this.promoCodeScope) { return this.$t('shop.promoCodes.scopes.update.button') }
    else { return this.$t('shop.promoCodes.scopes.create.button'); }
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();


    if(this.promoCodeScope) {  
      this.$emit('promoCodeScope-updated', { ...this.form});
    }
    else {  
      this.$emit('promoCodeScope-created', { ...this.form});
    }
    
  }
  
}
</script>